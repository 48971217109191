<template>
  <div id="app">
    <router-view />
  </div>

  <!-- No count down -->
</template>

<script>
export default {
  name: "App",

  mounted() {
    window.addEventListener("appinstalled", () => {
      localStorage.setItem("installURL", window.location.pathname);
    });
  },
};
</script>

<style>
#fioUiModal {
  z-index: 91 !important;
}
</style>
