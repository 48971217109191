import sa from "./axiosInstance";
import {
  StaffProfile,
  StaffNxOKin,
  // Educations,
  EmploymentInfo,
  PensionInfo,
} from "@/services/staffProfile";

export const fetchTitle = async () => {
  const res = await sa.get("/title");
  return res ? res.data : [];
};
export const fetchCountries = async () => {
  const res = await sa.get("/nationality");
  return res ? res.data.data : [];
};
export const fetchStates = async () => {
  const res = await sa.get("/state");
  return res ? res.data.data : [];
};
export const fetchLgas = async (stateCode) => {
  const res = await sa.get(`/lga?search=${stateCode}`);
  return res ? res.data.data : [];
};
export const fetchCities = async (lgaCode) => {
  const res = await sa.get(`/city?search=${lgaCode}`);
  return res ? res.data.data : [];
};
export const fetchStaffProfile = async () => {
  const res = await sa.get("/employee/me");
  return res ? res.data.data : StaffProfile;
};
export const fetchStaffNxOfKin = async () => {
  const res = await sa.get("/employee/next-of-kin");
  return res ? res.data.data : StaffNxOKin;
};
export const fetchRelationships = async () => {
  const res = await sa.get("/relationship");
  return res ? res.data.data : [];
};
export const fetchStaffEducation = async () => {
  const res = await sa.get("/employee/education");
  const educations = res && res.data.data ? res.data.data.education : [];
  const professionalBodies =
    res && res.data.data ? res.data.data.professionalBodies : [];
  return { educations, professionalBodies };
};
export const fetchCadres = async () => {
  const res = await sa.get("/cadre");
  return res ? res.data.data : [];
};
export const fetchSalaryGradeLevels = async () => {
  const res = await sa.get("/salary");
  return res ? res.data.data : [];
};
export const fetchGradeSteps = async () => {
  const res = await sa.get("/grade-step");
  return res ? res.data.data : [];
};
export const fetchMdas = async () => {
  const res = await sa.get("/mda");
  return res ? res.data.data : [];
};
export const fetchDepartments = async (searchTerm = null, mdaCode = null) => {
  const res = await sa.get(
    `/department?${searchTerm ? "search=" + searchTerm + "&" : ""} 
    ${mdaCode ? "mdaCode=" + mdaCode : ""}`
  );
  return res ? res.data.data : [];
};
export const fetchDesignations = async () => {
  const res = await sa.get("/designation");
  return res ? res.data.data : [];
};
export const fetchPfas = async () => {
  const res = await sa.get("/pfa");
  return res ? res.data.data : [];
};
export const fetchEmploymentInfo = async () => {
  const res = await sa.get("/employee/employment-info");
  return res ? res.data.data : EmploymentInfo;
};
export const fetchSalaryAccount = async () => {
  const res = await sa.get("/employee/salary-account");
  return res ? res.data.data : null;
};
export const fetchBanks = async () => {
  const res = await sa.get("/banks");
  return res ? res.data.data : [];
};
export const fetchQualifications = async () => {
  const res = await sa.get("/qualifications");
  return res ? res.data.data : [];
};
export const fetchDocSetup = async (staffType) => {
  const res = await sa.get(`/doc-setup?tag=${staffType}`);
  return res ? res.data.data : [];
};
export const fetchStaffDocuments = async () => {
  const res = await sa.get("/employee/document");
  return res ? res.data.data : [];
};
export const fetchUnions = async () => {
  const res = await sa.get("/unions");
  return res ? res.data.data : [];
};
export const fetchPensionInfo = async () => {
  const res = await sa.get("/pensionier/pensionier-info");
  return res ? res.data.data : PensionInfo;
};
export const fetchPensionZone = async () => {
  const res = await sa.get("/zones");
  return res ? res.data.data : [];
};
export const fetchAcademicCategories = async () => {
  const res = await sa.get("/academy-categories");
  return res ? res.data.data : [];
};
export const fetchAcademicDegrees = async (academyCode) => {
  const res = await sa.get(`/degree-categories?search=${academyCode}`);
  return res ? res.data.data : [];
};
export const fetchAcademicQualification = async (degreeCode) => {
  const res = await sa.get(`/qualification-categories?search=${degreeCode}`);
  return res ? res.data.data : [];
};
export const fetchProfessionalBodies = async () => {
  const res = await sa.get("/professional-bodies");
  return res ? res.data.data : [];
};
export const fetchProfessionalSkills = async () => {
  const res = await sa.get("/professional-skills");
  return res ? res.data.data : [];
};
