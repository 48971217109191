import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import { Toast } from "../vue-extensions/mixins";
import general from "./general";
import staff from "./staff";
import admin from "./admin";

Vue.use(VueRouter);

const routes = [
  ...general,
  ...staff,
  ...admin,
  {
    path: "/:pathMatch(.*)*",
    name: "Not Found",
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/PageNotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const title = to.name;
  // If the route has a title, set it as the page title of the document/page
  if (title) {
    document.title = `${process.env.VUE_APP_HEADER_NAME} OneID | ${title}`;
  }

  // allow un-protected pages
  if (to.meta.guest || !to.meta.requiresAuth) {
    next();
    return;
  }

  if (!localStorage.getItem(process.env.VUE_APP_tokenName)) {
    //also check if the token has expired
    next({
      path: "/login",
      query: { nextUrl: to.fullPath },
    });
    return;
  }

  // use permissions to block routes
  if (to.meta.permit && !store.getters["admin/permit"](to.meta.permit)) {
    Toast.fire({
      icon: "info",
      title: "Access denied!",
    });

    // next({
    //   path: from.path,
    // });
    return;
  }

  next();
  return;
});

export default router;
